import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";

import { motion } from "framer-motion";

import { useDispatchCart } from "../controllers/Cart";
import { useLanguage } from "../controllers/Language";
import Button from "./Layout/Button";

export default function ProductCard({ product, productInCart, userLoggedIn, type }) {
  const dispatch = useDispatchCart();
  const Language = useLanguage();

  const [bruttoDiscount, setBruttoDiscount] = useState(0);
  const [nettoDiscount, setNettoDiscount] = useState(0);

  const promoActionState = sessionStorage.getItem("promoActionState");
  const userType = sessionStorage.getItem("userType");
  let discount_value = "0.00";

  switch (userType) {
    case "partner30":
      discount_value = product.discount_value;
      break;
    case "rdz2":
      discount_value = product.discount_value_rdz;
      break;
    case "rdz":
      discount_value = product.discount_value_rdz;
      break;
    case "rdz3":
      discount_value = product.discount_value_rdz;
      break;
    case "client":
      discount_value = product.discount_value;
      break;
    default:
      discount_value = product.discount_value;
      break;
  }

  let brutto = 0;
  let netto = 0;

  if (product.category === "Personal Care" || product.id === 139) {
    switch (type) {
      case "partner30":
        brutto = product.brutto_a;
        netto = product.netto_a;
        break;
      case "rdz2":
        brutto = product.brutto_b;
        netto = product.netto_b;
        break;
      case "rdz":
        brutto = product.brutto_d;
        netto = product.netto_d;
        break;
      case "rdz3":
        brutto = product.brutto_e;
        netto = product.netto_e;
        break;
      case "client":
        brutto = product.brutto_c;
        netto = product.netto_c;
        break;
      default:
        brutto = product.brutto;
        netto = product.netto;
        break;
    }
  } else {
    switch (type) {
      case "partner30":
        brutto = product.brutto_a;
        netto = product.netto_a;
        break;
      case "rdz2":
        brutto = product.brutto_b;
        netto = product.netto_b;
        break;
      case "rdz":
        brutto = product.brutto_d;
        netto = product.netto_d;
        break;
      case "rdz3":
        brutto = product.brutto_e;
        netto = product.netto_e;
        break;

      default:
        brutto = product.brutto;
        netto = product.netto;
        break;
    }
  }

  useEffect(() => {
    if (discount_value !== "0.00") {
      if (product?.discount_type === "percent") {
        // const newBrutto = Math.floor((product.brutto_c * (parseFloat(discount_value) * 100)) / 100).toFixed(2);
        const newBrutto = Math.floor(product.brutto_c * parseFloat(discount_value)).toFixed();
        setBruttoDiscount(newBrutto);
        setNettoDiscount(Math.floor((newBrutto / 1.23) * 100) / 100);
      } else {
        setBruttoDiscount(parseFloat(discount_value));
        setNettoDiscount(Math.floor((parseFloat(discount_value) / 1.23) * 100) / 100);
      }
    } else {
      setBruttoDiscount(0);
      setNettoDiscount(0);
    }
  }, [product, discount_value, brutto]);

  const cartPayload = {
    productId: product.id,
    name: product.name,
    short_name: product.short_name,
    brutto: discount_value === "0.00" ? brutto : bruttoDiscount,
    netto: discount_value === "0.00" ? netto : nettoDiscount,
    vat: product.vat,
    ref_number: product.ref_number,
    quantity: 1,
    weight: product.weight,
  };

  const addToCart = (item) => {
    dispatch({ type: "ADD", item });
  };

  const variants = {
    active: {
      scale: 0.95,
      rotate: -3,
      transition: {
        duration: 0.9,
        type: "spring",
        stiffness: 100,
      },
    },
  };

  return (
    <>
      {userLoggedIn ? (
        <>
          {!product && !brutto ? (
            <h3>...</h3>
          ) : (
            <>
              <div className="card__top">
                <NavLink to={`/produkt/${product.id}`}>
                  <motion.div
                    className="thumbnail"
                    style={{ backgroundImage: `url(${product.main_img})` }}
                    whileHover="active"
                    variants={variants}
                  ></motion.div>
                </NavLink>

                {discount_value === "0.00" ? (
                  <>
                    <div className="card__heading">
                      <NavLink to={`/produkt/${product.id}`}>
                        <h3 className="card__product__name">{product.name}</h3>
                      </NavLink>
                      {!product.brutto ? <h3>...</h3> : <h3 className={`product__price ${Language}`}>{brutto}</h3>}
                    </div>
                  </>
                ) : (
                  <>
                    {promoActionState === "active" && discount_value !== "0.00" && <p className="font-bold text-orange-500 important">Promocja!</p>}
                    <div className="card__heading">
                      <NavLink to={`/produkt/${product.id}`}>
                        <h3 className="card__product__name">{product.name}</h3>
                      </NavLink>
                      {!product.brutto ? (
                        <h3>...</h3>
                      ) : (
                        <>
                          <h3 className={`product__price ${Language} discount-price-new`}>{bruttoDiscount}</h3>
                          <h3 className={`product__price ${Language} discount-price-old`}>{brutto}</h3>
                        </>
                      )}
                    </div>
                    {promoActionState === "active" && discount_value !== "0.00" && <p className="product__price">Cena detaliczna na 30 dni przed obniżką: {brutto} zł</p>}
                  </>
                )}
              </div>

              <div className="product__btn flex-between">
                {!productInCart ? (
                  <Button
                    content={"Dodaj do Koszyka"}
                    action={addToCart}
                    option={cartPayload}
                  />
                ) : (
                  <NavLink
                    to={`/koszyk`}
                    className="w100"
                  >
                    <button className="btn-alert w100">Zobacz koszyk</button>
                  </NavLink>
                )}
              </div>
            </>
          )}
        </>
      ) : (
        <>
          {!product ? (
            <h3>...</h3>
          ) : (
            <>
              <div className="card__top">
                <NavLink to={`/produkt/${product.id}`}>
                  <motion.div
                    className="thumbnail"
                    style={{ backgroundImage: `url('${product.main_img}')` }}
                    whileHover="active"
                    variants={variants}
                  ></motion.div>
                </NavLink>
                <div className="card__heading">
                  <NavLink to={`/produkt/${product.id}`}>
                    <h3 className="card__product__name">{product.name}</h3>
                  </NavLink>
                </div>
              </div>
              <div className="product__btn flex-between">
                <NavLink
                  to={`/login`}
                  className="w100"
                >
                  <button className="btn-alert w100">Zaloguj się aby zobaczyć ceny</button>
                </NavLink>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
}
