//basic
import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router";
import Axios from "axios";

import { apiUrl } from "../../Vars";

import CartItem from "../../components/CartItem";

import { useCart } from "../../controllers/Cart";
import { useDispatchCart } from "../../controllers/Cart";
import { useUser } from "../../controllers/UserContext";
import { useAuth } from "../../controllers/useAuth";
import { useLanguage } from "../../controllers/Language";

import LoginMonit from "../../components/Layout/LoginMonit";
import StartDiscount from "../../components/StartDiscount";
import WarningPop from "../../components/Static/WarningPop";
import CartItemList from "../../components/CartItemList";

export default function Koszyk() {
  const navigate = useNavigate();

  const userCart = useCart();
  const readCart = useDispatchCart();
  const dispatch = useDispatchCart();

  const userLoggedIn = useAuth();

  const userObject = useUser();

  const Language = useLanguage();

  const [cartValue, setCartValue] = useState();
  const [vatValue, setVatValue] = useState(0);
  const [discount, setDiscount] = useState();
  const [activeDiscount, setActiveDiscount] = useState(false);
  const [discountCartValue, setDiscountCartValue] = useState(0);
  const [discountValue, setDiscountValue] = useState(0);
  const [activeWarning, setActiveWarning] = useState(false);
  const [userType, setUserType] = useState(null);
  const [specialOffer, setSpecialOffer] = useState(false);
  const [specialOfferWarning, setSpecialOfferWarning] = useState(false);

  const promoActionState = sessionStorage.getItem("promoActionState");

  // console.log(activeWarning);

  // console.log(userCart);

  useEffect(() => {
    if (userCart.length !== 0) {
      userCart.forEach((item) => {
        if (item.productId >= 105 && item.productId <= 109) {
          setActiveWarning(true);
        }
      });
    }
  }, [userCart, cartValue]);

  useEffect(() => {
    if (userCart.length !== 0) {
      const itemsIds = [];
      userCart.forEach((item) => {
        itemsIds.push(item.productId);
      });
      console.log(itemsIds);
      if (itemsIds.includes(139) || itemsIds.includes(140) || itemsIds.includes(141) || itemsIds.includes(142)) {
        if (itemsIds.includes(139) && itemsIds.includes(140) && itemsIds.includes(141) && itemsIds.includes(142)) {
          setSpecialOfferWarning(false);
          setSpecialOffer(true);
          console.log("true");
        } else {
          setSpecialOfferWarning(true);
          setSpecialOffer(false);
          console.log("false");
        }
      }
    }
  }, [userCart, cartValue]);

  // useEffect(() => {
  //   if (specialOffer === true) {
  //     userCart.forEach((item) => {
  //       if (item.productId === 139) {
  //         dispatch({ type: "DELETE", item });
  //         const newItem = { ...item, brutto: (item.brutto * 0.7).toFixed(2), netto: ((item.brutto * 0.7) / 1.23).toFixed(2), quantity: item.quantity };
  //         dispatch({ type: "ADD", item: newItem });
  //       } else if (item.productId === 140) {
  //         dispatch({ type: "DELETE", item });
  //         const newItem = { ...item, brutto: (item.brutto * 0.7).toFixed(2), netto: ((item.brutto * 0.7) / 1.23).toFixed(2), quantity: item.quantity };
  //         dispatch({ type: "ADD", item: newItem });
  //       } else if (item.productId === 141) {
  //         dispatch({ type: "DELETE", item });
  //         const newItem = { ...item, brutto: (item.brutto * 0.7).toFixed(2), netto: ((item.brutto * 0.7) / 1.23).toFixed(2), quantity: item.quantity };
  //         dispatch({ type: "ADD", item: newItem });
  //       } else if (item.productId === 142) {
  //         dispatch({ type: "DELETE", item });
  //         const newItem = { ...item, brutto: (item.brutto * 0.7).toFixed(2), netto: ((item.brutto * 0.7) / 1.23).toFixed(2), quantity: item.quantity };
  //         dispatch({ type: "ADD", item: newItem });
  //       }
  //     });
  //   }
  // }, [specialOffer]);

  useEffect(() => {
    if (userObject) {
      setUserType(userObject.user?.userType);
    }
  }, [userObject]);

  useEffect(() => {
    if (userCart) {
      let value = 0;
      for (let i = 0; i < userCart.length; i++) {
        value = value + parseFloat(userCart[i].brutto) * userCart[i].quantity;
      }
      setCartValue(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCart]);

  useEffect(() => {
    if (userCart.length !== 0) {
      let value = 0;
      for (let i = 0; i < userCart.length; i++) {
        console.log(userCart[i].brutto);
        value = value + parseFloat(userCart[i].brutto) * (userCart[i].vat - 1) * userCart[i].quantity;
      }
      setVatValue(Math.round(value * 100) / 100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCart]);

  useEffect(() => {
    readCart({ type: "READ" });
  }, [readCart]);

  const applyDiscount = () => {
    Axios.post(`${apiUrl}/api/discount-validate`, {
      discount: discount,
      email: userObject.user.email,
    })
      .then((res) => {
        if (res.data.message) {
          alert(res.data.message);
        } else {
          const discountObject = res.data;
          setDiscountValue(discountObject.value);
          setActiveDiscount(true);
          sessionStorage.setItem("discount", JSON.stringify(discountObject));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    const percentage = parseInt(discountValue) / 100;
    setDiscountCartValue(Math.round((cartValue - cartValue * percentage) * 100) / 100);
  }, [discountValue, cartValue]);

  useEffect(() => {
    setVatValue(Math.round((discountCartValue - discountCartValue / 1.23) * 100) / 100);
  }, [discountCartValue]);

  // const disc = {
  //   "name": "kod03",
  //   "type": "value",
  //   "value": "35",
  //   "uses_count": "10",
  //   "for_users": "unlimited",
  //   "require_product": "null",
  //   "cart_value": "unlimited",
  //   "effect": "cart",
  //   "expire": "2023-12-01"
  // }

  const goToOrder = () => {
    Axios.post(`${apiUrl}/api/cart-create`, {
      userCart,
      userType,
    })
      .then((res) => {
        if (res.data.message === "Out of stock") {
          alert("Jednego Twoich produktów nie ma już na stanie");
          const prodOutOfStock = res.data.products;
          prodOutOfStock.forEach((i) => {
            const item = i.prodId;
            dispatch({ type: "DELETE", item });
          });
        } else {
          navigate(`/zamowienie?cart_id=${res.data}`);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      {specialOfferWarning === true ? (
        <div>
          <h2>Masz w koszyku produkty z serii Retinoid, ale aby skorzystać z promocji na nie musisz dodać je wszystkie</h2>
          <div className="cart__items">
            {userCart.map((cartProduct) => (
              <CartItemList
                key={cartProduct.productId}
                cartProduct={cartProduct}
                type={userObject.user.userType}
              />
            ))}
            <NavLink
              to={`/`}
              className="w100"
            >
              <button className="btn-alert w100 marginTop">Wszystkie produkty</button>
            </NavLink>
          </div>
        </div>
      ) : (
        <>
          <h2 className="title">Koszyk</h2>
          {!userLoggedIn ? (
            <LoginMonit location={"koszyk"} />
          ) : (
            <>
              {!userCart ? (
                <h3>Ładowanie...</h3>
              ) : userCart.length === 0 ? (
                <h3 className="alert">Nie masz jeszcze w koszyku żadnych produktów</h3>
              ) : (
                <div className="cart">
                  <div className="cart__items">
                    {userCart.map((cartProduct) => (
                      <CartItem
                        key={cartProduct.productId}
                        cartProduct={cartProduct}
                        type={userObject.user.userType}
                      />
                    ))}
                  </div>
                  <div className="cart__summary">
                    <div className="cart__details">
                      <h3 className="cart__item__qty">Suma: </h3>
                      <h3 className={`cart__item__price ${Language}`}>{cartValue}</h3>
                      <div className="cart__item__icon"></div>
                    </div>
                    {activeDiscount || specialOffer === true || promoActionState === "active" ? (
                      <div className="cart__details">
                        <h3 className="cart__item__qty">Kwota po rabacie: </h3>
                        <h3 className={`cart__item__price ${Language}`}>{discountCartValue.toFixed(2)}</h3>
                        <div className="cart__item__icon"></div>
                      </div>
                    ) : (
                      <></>
                    )}
                    <div className="cart__details">
                      <h4 className="">w tym: </h4>
                      <h4 className="">
                        VAT: <span className={`${Language}`}>{vatValue.toString()}</span>
                      </h4>
                      <div className="cart__item__icon"></div>
                    </div>
                    {activeDiscount || specialOffer === true || promoActionState === "active" ? (
                      <></>
                    ) : (
                      <div className="cart__discount">
                        <h4 className="">Kod rabatowy:</h4>
                        <form action="/discount">
                          <input
                            type="text"
                            name="discount"
                            onChange={(e) => setDiscount(e.target.value)}
                          />
                        </form>
                        <button
                          className="btn discount-btn"
                          onClick={() => applyDiscount()}
                        >
                          Zastosuj kod
                        </button>
                      </div>
                    )}
                    {specialOffer && promoActionState === "active" ? <div className="special_offer">Przyznaliśmy Ci rabat za dodanie do koszyka całego zestawu Retinoid! -30% na te produkty. </div> : <></>}
                    {promoActionState === "inactive" && (
                      <StartDiscount
                        userObject={userObject}
                        setDiscount={setDiscount}
                        setActiveDiscount={setActiveDiscount}
                        setDiscountValue={setDiscountValue}
                      />
                    )}

                    {/* <StartDiscount
                      userObject={userObject}
                      setDiscount={setDiscount}
                      setActiveDiscount={setActiveDiscount}
                      setDiscountValue={setDiscountValue}
                    /> */}

                    <button
                      className="btn"
                      onClick={() => goToOrder()}
                    >
                      Przejdź do zamówienia
                    </button>
                  </div>
                </div>
              )}
              {activeWarning ? <WarningPop setActiveWarning={setActiveWarning} /> : <></>}
            </>
          )}
        </>
      )}
    </>
  );
}
