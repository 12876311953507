import { useContext, useReducer, createContext } from "react";

const PromoActionStateContext = createContext();
const PromoActionDispatchContext = createContext();

const reducer = (state, action) => {
  switch (action.type) {
    case "CREATE":
      sessionStorage.setItem("promoActionState", "active");
      sessionStorage.setItem("promoActionName", action.state.promoActionName);
      return state;
    case "DELETE":
      sessionStorage.removeItem("promoActionState");
      sessionStorage.removeItem("promoActionName");
      return state;
    case "READ":
      const activeState = {
        promoActionState: sessionStorage.getItem("promoActionState"),
        promoActionName: sessionStorage.getItem("promoActionName"),
      };
      return activeState;
    default:
      sessionStorage.removeItem("promoActionState");
      sessionStorage.removeItem("promoActionName");
      return state;
  }
};

export function PromoActionProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, []);

  // useEffect(() => {
  //   dispatch({ type: "READ" });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <PromoActionDispatchContext.Provider value={dispatch}>
      <PromoActionStateContext.Provider value={state}>{children}</PromoActionStateContext.Provider>
    </PromoActionDispatchContext.Provider>
  );
}

export function usePromoAction() {
  return useContext(PromoActionStateContext);
}

export function useDispatchPromoAction() {
  return useContext(PromoActionDispatchContext);
}
