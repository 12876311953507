import React from "react";

export default function PromoBaner() {
  return (
    <div className="promoBaner">
      <h2>Promocja w Gen Factor!</h2>
      <p>Jeżeli zakupisz cały zestaw Retinoid, czyli Retinoid Set Cream, Retinoid Serum A, Retinoid Serum B i Retinoid Serum C, otrzymasz na nie 30% znizki!</p>
    </div>
  );
}
