import { useEffect } from "react";

import { useDispatchCart } from "../../controllers/Cart";

// import { FaList } from "react-icons/fa";
// import { FaGripHorizontal } from "react-icons/fa";

import Grid from "../../components/Grid";
// import List from "../../components/List";
import PromoBaner from "../../components/Layout/PromoBaner";

function Produkty() {
  // const [displayType, setDisplayType] = useState("grid");

  const dispatch = useDispatchCart();
  const promoActionState = sessionStorage.getItem("promoActionState");

  // const toggleDisplayType = (type) => {
  //   setDisplayType(type);
  // };

  useEffect(() => {
    dispatch({ type: "READ" });
  }, [dispatch]);

  return (
    <div className="produkty">
      {promoActionState === "active" && <PromoBaner />}
      {/* <div className="toggle__controls">
        <button
          className="btn__toggle w-8"
          onClick={() => toggleDisplayType("list")}
        >
          <FaList />
        </button>
        <button
          className="btn__toggle w-8"
          onClick={() => toggleDisplayType("grid")}
        >
          <FaGripHorizontal />
        </button>
      </div>
      {displayType === "grid" ? <Grid /> : <List />} */}
      <Grid />
    </div>
  );
}

export default Produkty;
