import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import Icons from "./Icons";
import Logo from "./Logo";
import Navigation from "./Navigation";
import LanguageSwitcher from "../LanguageSwitcher";
import Axios from "axios";
import { apiUrl } from "../../Vars";
// import { promoActionSet } from "../../controllers/PromoActionSet";
import { useDispatchPromoAction } from "../../controllers/PromoAction";

import { FaBars } from "react-icons/fa";

export default function Header({ handleModal }) {
  const location = useLocation();
  const [promoActionObject, setPromoActionObject] = useState(null);
  const [currentPath, setCurrentPath] = useState();

  useEffect(() => {
    setCurrentPath(location.pathname);
  }, [location]);

  useEffect(() => {
    const promoActionSet = async () => {
      const res = await Axios.get(`${apiUrl}/api/promo-action`, {
        headers: {
          "x-access-token": `${sessionStorage.getItem("token")}`,
        },
        withCredentials: true,
      });
      if (res?.data.length === 0) {
        setPromoActionObject(null);
      } else {
        setPromoActionObject(res.data[0]);
      }
    };
    console.log("check promo action");
    promoActionSet();
  }, [currentPath]);

  const dispatch = useDispatchPromoAction();
  useEffect(() => {
    if (!promoActionObject) {
      const state = {
        promoActionName: null,
        promoActionState: "inactive",
      };
      dispatch({ type: "DELETE", state });
    } else {
      console.log(promoActionObject);
      const state = {
        promoActionName: promoActionObject.name,
        promoActionState: promoActionObject.state,
      };
      dispatch({ type: "CREATE", state });
    }
  }, [promoActionObject, dispatch]);

  return (
    <>
      <div className="header_box">
        <div className="header_out">
          <Logo />
          <div className="header">
            <Navigation location={"desktop"} />
            <Icons location={"desktop"} />
            <div
              onClick={() => handleModal()}
              className="mobile"
            >
              <FaBars />
            </div>
            <LanguageSwitcher />
          </div>
        </div>
      </div>
    </>
  );
}
