//basic
import { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { UserProvider } from "./controllers/UserContext";
import { CartProvider } from "./controllers/Cart";
import { AuthProvider } from "./controllers/useAuth.jsx";
import { LanguageProvider } from "./controllers/Language";
// import { useAuth } from './controllers/useAuth.jsx';

import "./App.css";
import "./index.css";

import Header from "./components/Layout/Header";
import Footer from "./components/Layout/Footer";
import Produkty from "./routes/shop/Produkty";
import DesignSystem from "./routes/static/DesignSystem";
import Product from "./routes/shop/Produkt";
import Koszyk from "./routes/shop/StronaKoszyka";
import Logowanie from "./routes/user/Logowanie";
import Reset from "./routes/user/Reset";
import ResetFormPage from "./routes/user/ResetFormPage";
import Rejestracja from "./routes/user/Rejestracja";
import MojeKonto from "./routes/user/MojeKonto";
import Order from "./routes/shop/Zamowienie";
import Success from "./routes/shop/Success";
import Modal from "./components/Layout/Modal";
import MobileMenu from "./components/Layout/MobileMenu";
import ScrollToTop from "./components/Layout/ScrollToTop";
import Terms from "./routes/info/Terms";
import Cookies from "./routes/info/Cookies";
import Contact from "./routes/info/Contact";
import Faq from "./routes/info/Faq";
import Shipment from "./routes/info/Shipment";
import Payments from "./routes/info/Payments";
import Skeletons from "./routes/static/Skeletons";
import AuthInfo from "./components/Layout/AuthInfo";
import Help from "./routes/info/Help.jsx";
import LoginReferral from "./routes/user/LogowanieAmbasador.jsx";
import DevStateBar from "./components/Layout/DevStateBar.jsx";
import { PromoActionProvider } from "./controllers/PromoAction.jsx";

function App() {
  const [isModal, setIsModal] = useState(false);

  // const authUser = useAuth()

  const handleModal = () => {
    setIsModal((prevIsModal) => !prevIsModal);
  };

  return (
    <div className="App">
      <BrowserRouter>
        <LanguageProvider>
          <PromoActionProvider>
            <UserProvider>
              <AuthProvider>
                <CartProvider>
                  <DevStateBar />
                  <AuthInfo />
                  <Header handleModal={handleModal} />
                  <Modal
                    isModal={isModal}
                    handleModal={handleModal}
                  >
                    <MobileMenu handleModal={handleModal} />
                  </Modal>
                  <div className="main">
                    <ScrollToTop>
                      <Routes>
                        <Route
                          path="/"
                          element={<Produkty />}
                        />
                        <Route
                          path="/design-system"
                          element={<DesignSystem />}
                        />
                        <Route
                          path="/koszyk"
                          element={<Koszyk />}
                        />
                        <Route
                          path="/produkt/:id"
                          element={<Product />}
                        />
                        <Route
                          path="/login"
                          element={<Logowanie />}
                        />
                        <Route
                          path="/login-ref"
                          element={<LoginReferral />}
                        />
                        <Route
                          path="/reset"
                          element={<Reset />}
                        />
                        <Route
                          path="/password-reset"
                          element={<ResetFormPage />}
                        />
                        <Route
                          path="/rejestracja"
                          element={<Rejestracja />}
                        />
                        <Route
                          path="/moje-konto"
                          element={<MojeKonto />}
                        />
                        <Route
                          path="/terms"
                          element={<Terms />}
                        />
                        <Route
                          path="/payments"
                          element={<Payments />}
                        />
                        <Route
                          path="/shipment"
                          element={<Shipment />}
                        />
                        <Route
                          path="/faq"
                          element={<Faq />}
                        />
                        <Route
                          path="/cookies"
                          element={<Cookies />}
                        />
                        <Route
                          path="/contact"
                          element={<Contact />}
                        />
                        <Route
                          path="/zamowienie"
                          element={<Order />}
                        />
                        <Route
                          path="/zamowienie-przyjete"
                          element={<Success />}
                        />
                        <Route
                          path="/skeletons"
                          element={<Skeletons />}
                        />
                        <Route
                          path="/pomoc"
                          element={<Help />}
                        />
                      </Routes>
                    </ScrollToTop>
                  </div>
                  <Footer />
                </CartProvider>
              </AuthProvider>
            </UserProvider>
          </PromoActionProvider>
        </LanguageProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
